const imagesArr = [require('@assets/img/wheel/reward-effect.webp'), require('@assets/img/wheel/lose-effect.webp')];

function Preloader() {
  return (
    <>
      <div className="hidden">{imagesArr && imagesArr.map((imageURL, idx: number) => <img src={imageURL} alt="" key={`image${idx}`} />)}</div>
    </>
  );
}

export default Preloader;
