import styles from './Menu.module.scss';

import paths from '@configs/Router/paths';

import Container from '../Container';
import MenuItem from './MenuItem';

function Menu() {
  return (
    <div className={styles.Menu}>
      <Container>
        <nav>
          <ul className="pt-[8%] flex justify-between items-end">
            <MenuItem imageURL={require('@assets/img/menu/rating.webp')} title="Rating" to={paths.Rating} />

            <MenuItem imageURL={require('@assets/img/menu/earn.webp')} title="Earn" to={paths.Earn} />

            <MenuItem imageURL={require('@assets/img/menu/wheel.webp')} title="Wheel" to={paths.Home} />

            <MenuItem imageURL={require('@assets/img/menu/friends.webp')} title="Friends" to={paths.Friends} />

            <MenuItem imageURL={require('@assets/img/menu/shop.webp')} title="Shop" to={paths.Shop} />
          </ul>
        </nav>
      </Container>
    </div>
  );
}

export default Menu;
