// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/fonts/SkrampCyr.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Skramp Cyr";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AAAF","sourcesContent":["@use '@styles/mixins.scss' as *;\n@font-face {\r\n  font-family: 'Skramp Cyr';\r\n  src: url('@assets/fonts/SkrampCyr.ttf') format('truetype');\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
