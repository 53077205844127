import { Outlet } from 'react-router-dom';

import Preloader from './Preloader';
import Menu from './Menu';

function MainLayout() {
  return (
    <div className="wrapper">
      <Preloader />

      <Menu />

      <main className="flex flex-col flex-auto overflow-hidden">
        <Outlet />
      </main>
    </div>
  );
}

export default MainLayout;
