// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/img/menu.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sJ22Sdxm26RIPWBrjMv4 {
  width: 100%;
  min-height: 130px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media (max-width: 430px) {
  .sJ22Sdxm26RIPWBrjMv4 {
    min-height: calc(100px - -30 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .sJ22Sdxm26RIPWBrjMv4 {
    min-height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Menu/Menu.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;EACE,WAAA;ECCA,iBAAA;EDCA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;EACA,yDAAA;AAAF;ACNE;EDJF;ICQI,qDAAA;EDMF;AACF;ACJE;EDXF;ICYI,iBAAA;EDOF;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.Menu {\r\n  width: 100%;\r\n  @include fluid(min-height, 130px, 100px);\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  z-index: 20;\r\n  background-position: bottom;\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-image: url('@assets/img/menu.webp');\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Menu": `sJ22Sdxm26RIPWBrjMv4`
};
export default ___CSS_LOADER_EXPORT___;
