import React from 'react';
import classNames from 'classnames';

import './index.scss';
import styles from './Wheel.module.scss';

import WheelOfFortune from './utils/wheel.js';

import Button from '@components/UI/Button';

let wheelOfFortune: any = null;

const rewards = [
  {
    image: require('@assets/img/currencies/coin.webp'),
    value: 150,
  },
  {
    image: require('@assets/img/currencies/coin.webp'),
    value: 150,
  },
  {
    image: require('@assets/img/currencies/ton.webp'),
    value: 3,
  },
  {
    image: require('@assets/img/currencies/coin.webp'),
    value: 1000,
  },
  {
    image: require('@assets/img/wheel/ticket.webp'),
    value: 1,
  },
  {
    image: require('@assets/img/wheel/lose.webp'),
    value: 'LOSE',
  },
  {
    image: require('@assets/img/currencies/ton.webp'),
    value: 0.5,
  },
  {
    image: require('@assets/img/currencies/coin.webp'),
    value: 100,
  },
];

function Wheel() {
  let randomChoice = 0;

  const [isWheelSpinning, setIsWheelSpinning] = React.useState(false);
  const [activeReward, setActiveReward] = React.useState(null);

  const wheelContent = React.useMemo(() => {
    return rewards.map(({ image, value }) => {
      return `
			  <div class="wheel-item">
          <span>
            <img src="${image}" alt="${value}">
          
            <p>${value}</p>
          </span>
        </div>
		  `;
    });
  }, [rewards]);

  React.useEffect(() => {
    wheelOfFortune = new WheelOfFortune(document.querySelector('.wheel-of-fortune'), wheelContent);
  }, []);

  const onSpin = async () => {
    try {
      setIsWheelSpinning(true);
      randomChoice = Math.floor(Math.random() * wheelContent.length);

      if (wheelOfFortune) {
        await wheelOfFortune.spin(randomChoice);
        setActiveReward(rewards[randomChoice]);

        setTimeout(() => {
          setActiveReward(null);
          setIsWheelSpinning(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="h-[clamp()] flex flex-col items-center">
      <div className={styles.WheelContainer}>
        <div className={styles.Wheel}>
          <div className={styles.WheelOverflow}>
            <button className={styles.WheelSpinButton} onClick={onSpin} disabled={isWheelSpinning} />
          </div>

          <div className="wheel-of-fortune"></div>
        </div>

        {activeReward && (
          <div className={classNames('wheel-effect', activeReward?.value === 'LOSE' ? 'wheel-effect-lose' : 'wheel-effect-reward')}>
            <div className="wheel-effect-inner">
              <i>
                <img src={activeReward?.image} alt={activeReward?.value} />
              </i>

              <span>{activeReward?.value}</span>
            </div>
          </div>
        )}
      </div>

      <Button className={styles.WheelButton} onClick={onSpin} disabled={isWheelSpinning}>
        SPIN FOR
        <i className={styles.WheelSpinButtonIcon}>
          <img src={require('@assets/img/wheel/ticket.webp')} alt="Ticket" />
        </i>
      </Button>
    </div>
  );
}

export default Wheel;
