import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './MenuItem.module.scss';

type Props = {
  imageURL: string;
  title: string;
  to: string;
};

const MenuItem: React.FC<Props> = ({ imageURL, title, to }) => {
  return (
    <li>
      <NavLink className={({ isActive }) => (isActive ? styles.MenuItemActive : styles.MenuItem)} to={to}>
        <i>
          <img src={imageURL} alt={title} />
        </i>

        <span>{title}</span>
      </NavLink>
    </li>
  );
};

export default React.memo(MenuItem);
