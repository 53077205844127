// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KRbLGpjTYZSO792fUER1,
.QpJcvN09g0lcggwUkEG4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.KRbLGpjTYZSO792fUER1 i,
.QpJcvN09g0lcggwUkEG4 i {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
@media (max-width: 430px) {
  .KRbLGpjTYZSO792fUER1 i,
  .QpJcvN09g0lcggwUkEG4 i {
    width: calc(40px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .KRbLGpjTYZSO792fUER1 i,
  .QpJcvN09g0lcggwUkEG4 i {
    width: 40px;
  }
}
@media (max-width: 430px) {
  .KRbLGpjTYZSO792fUER1 i,
  .QpJcvN09g0lcggwUkEG4 i {
    height: calc(40px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .KRbLGpjTYZSO792fUER1 i,
  .QpJcvN09g0lcggwUkEG4 i {
    height: 40px;
  }
}
.KRbLGpjTYZSO792fUER1 i img,
.QpJcvN09g0lcggwUkEG4 i img {
  max-width: 100%;
}
.KRbLGpjTYZSO792fUER1 i:not(:last-child),
.QpJcvN09g0lcggwUkEG4 i:not(:last-child) {
  margin-bottom: 4px;
}
@media (max-width: 430px) {
  .KRbLGpjTYZSO792fUER1 i:not(:last-child),
  .QpJcvN09g0lcggwUkEG4 i:not(:last-child) {
    margin-bottom: calc(2px - -2 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .KRbLGpjTYZSO792fUER1 i:not(:last-child),
  .QpJcvN09g0lcggwUkEG4 i:not(:last-child) {
    margin-bottom: 2px;
  }
}
.KRbLGpjTYZSO792fUER1 span,
.QpJcvN09g0lcggwUkEG4 span {
  text-align: center;
}

.QpJcvN09g0lcggwUkEG4 {
  pointer-events: none;
}
.QpJcvN09g0lcggwUkEG4 i {
  transform: translateY(-16px) scale(1.8);
}
@media (max-width: 375px) {
  .QpJcvN09g0lcggwUkEG4 i {
    transform: translateY(-12px) scale(1.5);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Menu/MenuItem/MenuItem.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;;EAEE,aAAA;EACA,sBAAA;EACA,mBAAA;AAAF;AAEE;;ECJA,WAAA;EAAA,YAAA;EDOE,cAAA;AACJ;ACNE;EDEA;;ICEE,8CAAA;EDOF;AACF;ACLE;EDLA;;ICME,WAAA;EDSF;AACF;AClBE;EDEA;;ICEE,+CAAA;EDmBF;AACF;ACjBE;EDLA;;ICME,YAAA;EDqBF;AACF;AAvBI;;EACE,eAAA;AA0BN;AAvBI;;ECbF,kBAAA;ADwCF;ACtCE;EDWE;;ICPA,qDAAA;EDuCF;AACF;ACrCE;EDIE;;ICHA,kBAAA;EDyCF;AACF;AAlCE;;EACE,kBAAA;AAqCJ;;AAjCA;EACE,oBAAA;AAoCF;AAlCE;EACE,uCAAA;AAoCJ;AAjCE;EACE;IACE,uCAAA;EAmCJ;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.MenuItem,\r\n.MenuItemActive {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n\r\n  i {\r\n    @include fluid(width, 48px, 40px);\r\n    @include fluid(height, 48px, 40px);\r\n    flex-shrink: 0;\r\n\r\n    img {\r\n      max-width: 100%;\r\n    }\r\n\r\n    &:not(:last-child) {\r\n      @include fluid(margin-bottom, 4px, 2px);\r\n    }\r\n  }\r\n\r\n  span {\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n.MenuItemActive {\r\n  pointer-events: none;\r\n\r\n  i {\r\n    transform: translateY(-16px) scale(1.8);\r\n  }\r\n\r\n  @media (max-width: 375px) {\r\n    i {\r\n      transform: translateY(-12px) scale(1.5);\r\n    }\r\n  }\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuItem": `KRbLGpjTYZSO792fUER1`,
	"MenuItemActive": `QpJcvN09g0lcggwUkEG4`
};
export default ___CSS_LOADER_EXPORT___;
