import React from 'react';

import { useAppDispatch } from './store';
import { fetchGetUser } from '@store/slices/auth';

import Router from '@configs/Router';

function App() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchGetUser());
  }, []);

  return <Router />;
}

export default App;
