import React from 'react';

import styles from './UserControls.module.scss';

const UserControls: React.FC = () => {
  return (
    <div className={styles.UserControls}>
      <div className="mr-[5px] flex items-center">
        <i className={styles.CoinLogo}>
          <img src={require('@assets/img/currencies/coin.webp')} alt="Coin" />
        </i>

        <div className="flex items-center">
          <span className={styles.Text1}>1234567</span>

          <span className={styles.Text2}>$12.34</span>
        </div>
      </div>

      <button className={styles.SettingsButton} type="button">
        <img src={require('@assets/img/settings.webp')} alt="Settings" />
      </button>
    </div>
  );
};

export default UserControls;
