import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import BalanceItem from './components/BalanceItem';
import Wheel from './components/Wheel';
import Marquee from './components/Marquee';

function Home() {
  return (
    <PageTemplate className="pt-[4px]">
      <div className="flex flex-col flex-auto justify-around">
        <Container>
          <UserControls />

          <div className="flex justify-between items-center relative z-[3]">
            <BalanceItem name="Ticket" count={1234} leftImageURL={require('@assets/img/wheel/ticket.webp')} rightImageURL={require('@assets/img/wheel/add.webp')} />

            <BalanceItem name="Ton" count={1234} leftImageURL={require('@assets/img/currencies/ton.webp')} rightImageURL={require('@assets/img/wheel/topup.webp')} />
          </div>
        </Container>

        <Wheel />

        <Marquee />
      </div>
    </PageTemplate>
  );
}

export default Home;
