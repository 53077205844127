import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  before?: boolean;
  after?: boolean;
  backgroundImage?: React.CSSProperties['backgroundImage'];
};

function PageTemplate({ children, className, style, before = true, after = false, backgroundImage = `url(${require('@assets/img/bg/main.webp')})` }: Props) {
  return (
    <div className={classNames('page-template', className, !before && 'before:hidden', !after && 'after:hidden')} style={{ ...style, backgroundImage }}>
      <div className="flex flex-col flex-auto relative z-[3]">{children}</div>
    </div>
  );
}

export default PageTemplate;
