import axios, { type AxiosInstance } from 'axios';

export const initDataString =
  'query_id=AAEWgtYsAgAAABaC1iz_m6Gm&user=%7B%22id%22%3A5047222806%2C%22first_name%22%3A%22%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22developer5417%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FRo6DoEFpONhnkuKcpZIRrHUMS6ciiGVkVkKI69HcuabPrpnMYxkJVSXlKtFYwtkD.svg%22%7D&auth_date=1733944275&signature=BnCDfnaZo7SLBUiklzrHoNmZqq37OqE70ADfgfA1aLXOPQhXJKGYdLgGH3nvrOcMXnkeGeRDwyFVRR2cUUMpCg&hash=8251984fd82dd2fa50e3e59100e5a3887a1d452ab90336dbaad9146b1c063592';

export const isInitData = false;

export const BASE_URL: string = 'https://spin-api.m-bot.site';

export const API_URL: string = `${BASE_URL}/api`;

export let token: string = '';

export const setAuthToken = (newToken: string) => {
  token = newToken;
};

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
});

API.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
