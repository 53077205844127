import React from 'react';

import styles from './BalanceItem.module.scss';

type Props = {
  name: string;
  count: number;
  leftImageURL: string;
  rightImageURL: string;
};

const BalanceItem: React.FC<Props> = ({ name, count, leftImageURL, rightImageURL }) => {
  return (
    <div className={styles.BalanceItem}>
      <i className={styles.BalanceItemLeftImage}>
        <img src={leftImageURL} alt={name} />
      </i>

      <span className={styles.BalanceItemCount}>
        <span className={styles.BalanceItemCountSpan}>{count}</span>
      </span>

      <i className={styles.BalanceItemRightImage}>
        <img src={rightImageURL} alt={name} />
      </i>
    </div>
  );
};

export default React.memo(BalanceItem);
