import React from 'react';

import FastMarquee from 'react-fast-marquee';
import MarqueeItem from './MarqueeItem';

const Marquee: React.FC = () => {
  return (
    <FastMarquee autoFill>
      <MarqueeItem />

      <MarqueeItem />
    </FastMarquee>
  );
};

export default Marquee;
