import React from 'react';

import styles from './Marquee.module.scss';

type Props = {};

const MarqueeItem: React.FC<Props> = ({}) => {
  return (
    <div className={styles.MarqueeItem}>
      <span className={styles.MarqueeItemText1}>User 123 win</span>

      <span className={styles.MarqueeItemText2}>1000</span>

      <i className={styles.MarqueeItemLogo}>
        <img src={require('@assets/img/currencies/coin.webp')} alt="Coin" />
      </i>
    </div>
  );
};

export default React.memo(MarqueeItem);
