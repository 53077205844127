import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';

import type IUserModel from '@interfaces/models/UserModel';
import type { TypeStatus } from '@interfaces/status.types';

import { API, initDataString, isInitData, setAuthToken } from '@services/API';

import { toastText } from '@configs/constants/toast';

// Отправляем запрос на бек с получением пользователя
export const fetchGetUser: any = createAsyncThunk('fetchGetUser', async () => {
  try {
    const webApp = window.Telegram.WebApp;
    const initData = webApp.initData;
    const startApp = webApp.initDataUnsafe.start_param;

    const initDataValue = isInitData ? initData : initDataString;

    if (!initDataValue || initDataValue === '') {
      window.alert(toastText.error);

      return;
    }

    const { data } = await API.post('/user/login', {
      initData: initDataValue,
      EnterReferralCode: startApp || '',
    });

    if (!data) return;

    const { token, user, settings } = data; // Достаем данные пользователя и JWT токен

    setAuthToken(token); // Сохраняем JWT токен

    return user; // Возвращаем данные пользователя
  } catch (err) {
    const message = err?.response?.data?.message || toastText.error;

    window.alert(message);
  }
});

interface IAuth {
  user: IUserModel;

  userStatus: TypeStatus;
}

const initialState: IAuth = {
  user: null,

  userStatus: 'loading',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // setTemplate: (state, { payload }: PayloadAction<any>) => {
    //   state.template = payload;
    // },
  },
  extraReducers: {
    // Получаем пользователя
    [fetchGetUser.fulfilled]: (state, { payload }: PayloadAction<IUserModel | string>) => {
      if (payload === undefined) {
        state.userStatus = 'error';

        return;
      }

      if (payload) {
        state.user = {
          ...(payload as IUserModel),
        };
      } else {
        state.userStatus = 'error';
      }
    },
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;
