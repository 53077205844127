// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #ffffff;
  --black: #222222;
}`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,gBAAA;AAAF","sourcesContent":["@use '@styles/mixins.scss' as *;\n:root {\r\n  --white: #ffffff;\r\n  --black: #222222;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
